import styled from "styled-components";

export const InfoContainer = styled.div`
  color: #fff;
  background: ${({ lightBg }) => (lightBg ? "#f9f9f9" : "#1C202F")};

  @media screen and (max-width: 48em) {
    padding: 3em 0;
  }
`;

export const InfoWrapper = styled.div``;

export const InfoRow = styled.div`
  display: flex;
  flex-direction: ${({ imgStart }) => (imgStart ? "row-reverse" : "row")};
  justify-content: center;
  gap: 3em;
  align-items: center;
  padding-block: 9em;
  margin-inline: 2em;

  @media screen and (max-width: 48em) {
    flex-direction: column;
    padding-block: 4em;
  }
  @media screen and (max-width: 30em) {
    margin-inline: 1em;
  }
`;

export const Column1 = styled.div`
  max-width: 28em;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 48em) {
    justify-content: center;
    text-align: center;
  }
`;

export const Heading = styled.h1`
  margin-bottom: 0.5em;
  font-size: 1.8rem;
  line-height: 1.4;
  font-family: "Poppins";
  color: ${({ lightText }) => (lightText ? "#fff" : "#053146")};

  @media screen and (max-width: 48em) {
    font-size: 1.65rem;
  }
  @media screen and (max-width: 30em) {
    font-size: 1.6rem;
  }
`;

export const Text = styled.p`
  margin-bottom: 1.5em;
  font-size: 1.3rem;
  line-height: 1.3em;
  max-width: 420px;
  color: ${({ darkText }) => (darkText ? "#010606" : "#fff")};

  @media screen and (max-width: 48em) {
    font-size: 1.16rem;
    line-height: 1.3em;
  }
  @media screen and (max-width: 30em) {
    font-size: 1.1rem;
  }
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;

  @media screen and (max-width: 48em) {
    justify-content: center;
  }
`;

export const Column2 = styled.div``;

export const ImgWrap = styled.div`
  max-width: 26em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Img = styled.img`
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.4);
  max-width: 100%;
  height: auto;
  /* background-color: #c7e6f8; */
`;

const data = [
  {
    question: "What type of learning do you offer?",
    answer: `Academic home lesson and tech skill acqusition`,
  },
  {
    question: "Is learning free? and Where is it done?",
    answer: `Learning is done online. For physical class, 
    contact us to get a tutor`,
  },
  {
    question: "Do you issue a certificate after learning a skill?",
    answer: `Yes, for every skill acquired, a certificate is 
    awarded`,
  },
  {
    question: "Can a child take home lesson & skill aquisition?",
    answer: `Yes, we can even help prepare a time table for it`,
  },
];

export default data;

import React from "react";
import {
  BusinessContainer,
  Ad,
  H1,
  Para,
  Div,
  AdImgBox,
  ButtonBox,
} from "./BusinessElement";

import { ATagButton } from "../ButtonElements";
import Slider from "../../images/slider3.png";

const Business = () => {
  return (
    <BusinessContainer>
      <Ad>
        <Div>
          <H1>INTENSIVE TECH SKILL AQUISITION</H1>
          <Para>It's time for your kid to have a tech skill</Para>
          <ButtonBox>
            <a href="https://api.whatsapp.com/send?phone=2348021468329">
              <ATagButton>Enroll your child NOW!!</ATagButton>
            </a>
          </ButtonBox>
        </Div>
        <AdImgBox>
          <img src={Slider} alt="SLIDER" />
        </AdImgBox>
      </Ad>
    </BusinessContainer>
  );
};

export default Business;

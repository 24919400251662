import { useState } from "react";
import { animateScroll as scroll } from "react-scroll";
import { Button } from "../ButtonElements";
import heroImg from "../../images/learning-child.jpg";
import teacher from "../../images/home-tutor.png";
import video from "../../videos/video-4.mp4";
import {
  HeroContainer,
  HeroChild,
  HeroContent,
  HeroImage,
  HeroImageContainer,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForword,
  ArrowRight,
  TopImage,
  Image,
  Video,
} from "./HeroElements";

const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <HeroContainer id="home">
      <Video autoPlay muted loop>
        <source src={video} type="video/mp4" />
      </Video>

      <HeroChild>
        <TopImage>
          <Image color="red" width="100px" height="100px" src={teacher} />
        </TopImage>
        <HeroContent>
          <HeroH1>Learn, Skillup & Grow</HeroH1>
          <HeroP>
            Home lesson and acquisition of relevant tech skills 
            for primary and secondary school children.
          </HeroP>
          <HeroBtnWrapper>
            <Button
              to="/form"
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              primary="true"
              dark="true"
              onClick={toggleHome}
            >
              Get started {hover ? <ArrowForword /> : <ArrowRight />}
            </Button>
          </HeroBtnWrapper>
        </HeroContent>
        <HeroImageContainer>
          <HeroImage width="360px" height="360px" src={heroImg} />
        </HeroImageContainer>
      </HeroChild>
    </HeroContainer>
  );
};

export default HeroSection;

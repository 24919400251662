import Footer from "../Footer";
import Hero from "./Hero";
import AllPrices from "./AllPrices";

const GetStarted = () => {
  return (
    <>
      <Hero />
      <AllPrices />
      <Footer />
    </>
  );
};

export default GetStarted;

import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { BsCreditCardFill } from "react-icons/bs";
import { SiStarlingbank } from "react-icons/si";
import { ImArrowDown } from "react-icons/im";

export const ContactSection = styled.section`
  background-color: #c7e6f8;
`;

export const ContactContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5em;
  padding-inline: 1em;

  @media screen and (min-width: 60em) {
    padding-block: 10em;
  }
`;

export const FormContainer = styled.div`
  max-width: 100%;
  background: #fff;
  margin-bottom: -4.8em;
  z-index: 1;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border-radius: 5px;
  position: relative;

  @media screen and (min-width: 48em) {
    margin-bottom: 0em;
    border-radius: 5px 5px 0 0;
  }

  @media screen and (min-width: 60em) {
    border-radius: 5px;
  }
`;

export const FormHeader = styled.h2`
  color: #053146;
  color: rgba(0, 0, 0, 0.65);
  font-family: cursive, "Poppins", sans-serif;
  text-align: center;
  font-size: 1.3rem;
  line-height: 1.14;

  @media screen and (min-width: 48em) {
    font-size: 1.6rem;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2em;
  padding: 1.4em;

  @media screen and (min-width: 48em) {
    max-width: 500px;
    margin: auto;
  }

  @media screen and (min-width: 60em) {
    max-width: 600px;
  }

  hr {
    border: 2px solid #840d0d;
  }
`;

export const Button = styled.a`
  padding: 0.8em;
  border-radius: 5px;
  margin-top: 0.4em;
  background-color: #053146;
  font-size: 1.2rem;
  color: #fff;
  text-align: center;

  @media screen and (min-width: 48em) {
    font-size: 1.3rem;
  }

  &:hover {
    scale: 0.99;
    background: linear-gradient(
      148deg,
      rgba(45, 98, 122, 1) 6%,
      rgba(14, 70, 97, 1) 34%,
      rgba(5, 48, 69, 1) 66%,
      rgba(5, 48, 69, 1) 100%
    );
    transition: all 300ms ease-in-out;
  }
`;

export const ContactInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2em;
`;

export const Linked = styled(Link)`
  position: absolute;
  top: 0.6em;
  left: 0;
  z-index: 2;
`;

export const ImageLink = styled.div`
  max-width: 100px;
  padding: 0.5em 1em;

  @media screen and (min-width: 20em) {
    max-width: 120px;
  }
  @media screen and (min-width: 48em) {
    max-width: 140px;
  }

  img {
    width: 100%;
  }
`;

export const PaystackImgDiv = styled.div`
  max-width: 150px;

  @media screen and (min-width: 48em) {
    max-width: 215px;
  }

  img {
    width: 100%;
  }
`;

export const H2 = styled.h2`
  font-size: 1.1rem;
  opacity: 0.88;
  text-align: center;

  padding-block: 0.6em;
  color: #fff;
  border-radius: 3px;
  background: linear-gradient(to bottom left, #053146, #00e3ae);

  @media screen and (min-width: 48em) {
    font-size: 1.3rem;
  }
`;

export const Span = styled.span`
  position: absolute;
  top: 1em;
  right: 1em;
  background: linear-gradient(to top right, #043246, #00e3ae);
  color: #fff;
  padding: 0.6em 0.8em;
  border-radius: 3px;
  transform: skewY(6deg);
  font-size: 1rem;
  font-weight: 600;

  @media screen and (min-width: 30em) {
    padding: 0.5em 1em;
    font-size: 1.3rem;
  }
  @media screen and (min-width: 48em) {
    right: 1.2em;
    font-size: 1.5rem;
    padding: 0.7em 1em;
  }
`;

export const PaystackSpan = styled.span`
  margin-left: 0.2em;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  font-size: 1.04rem;
  line-height: 1.8;

  @media screen and (min-width: 48em) {
    font-size: 1.2rem;
    line-height: 1.4;
  }
`;

export const Card = styled(BsCreditCardFill)`
  font-size: 1.4rem;
  margin-bottom: -0.2em;
  margin-left: 0.4em;
  color: #053146;

  @media screen and (min-width: 48em) {
    font-size: 1.8em;
  }
`;

export const Bank = styled(SiStarlingbank)`
  font-size: 1.4rem;
  margin-bottom: -0.2em;
  margin-left: 0.4em;
  color: #053146;

  @media screen and (min-width: 48em) {
    font-size: 1.8em;
  }
`;

const vertical = keyframes`
 0%{
  transform: translateY(-4px);
 }
 50%{
  transform: translateY(12px);
  color: #900603;
 }
 100%{
  transform: translateY(-4px);
 }
`;

export const Arrow = styled(ImArrowDown)`
  font-size: 1.8rem;
  margin-bottom: -0.2em;
  margin-left: 0.4em;
  color: #053146;
  position: absolute;
  right: 1.2em;
  animation: ${vertical} linear 2.6s infinite;

  @media screen and (min-width: 48em) {
    font-size: 2em;
    bottom: 4.2em;
  }
`;
///////////////////////////////////////////////////////

export const PaymentWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 30em) {
    gap: 1em;
    flex-direction: column;
  }
`;

export const Image = styled.img`
  width: 20%;

  @media screen and (max-width: 30em) {
    width: 15%;
  }
`;

export const ExternalLink = styled.a`
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* margin-bottom: 1.4em; */
  /* padding: 0.6em 1em; */
  padding-inline: 0.5em;

  border-radius: 3px;
  cursor: pointer;
  color: #000;
  font-weight: 600;

  & p {
    font-size: 1.2rem;
    margin-left: 0.6em;
    text-align: center;

    @media screen and (max-width: 30em) {
      font-size: 1.1rem;
    }
  }
`;

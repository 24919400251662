import PayInfo from "../components/PayInfo";
import Footer from "../components/Footer";

const CodingPage = () => {
  return (
    <div>
      <PayInfo />
      <Footer />
    </div>
  );
};

export default CodingPage;

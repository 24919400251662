import styled from "styled-components";
import whatsapp from "../images/whatsapp.png";
import call from "../images/call.png";
import { FaHome } from "react-icons/fa";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { Link } from "react-router-dom";

const Container = styled.section`
  height: 100vh;
  background-color: #c7e6f8;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-inline: 1em;
  overflow: hidden;
  background: linear-gradient(to top right, #043246, #00e3ae);
`;

const PayHeader = styled.h2`
  margin-bottom: 1.85em;
  font-size: 1.2rem;
  text-align: center;
  max-width: 340px;
  color: #c7e6f8;

  @media screen and (max-width: 48em) {
    font-size: 1.1rem;
    max-width: 260px;
  }
`;

const PaymentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Image = styled.img`
  width: 20%;

  @media screen and (max-width: 30em) {
    width: 15%;
  }
`;

const Home = styled(FaHome)`
  position: absolute;
  top: 30px;
  left: 40px;
  font-size: 4.2rem;
  color: #fff;

  @media screen and (max-width: 30em) {
    left: 30px;
    font-size: 3rem;
  }
`;

const ExternalLink = styled.a`
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1.4em;
  padding: 0.6em 1em;
  border-radius: 3px;
  cursor: pointer;
  color: #000;
  font-weight: 600;

  & p {
    font-size: 1.2rem;
    margin-left: 1.6em;
    text-align: center;

    @media screen and (max-width: 30em) {
      font-size: 1.1rem;
    }
    @media screen and (max-width: 26em) {
      margin-left: 1em;
    }
  }
`;

const Mark = styled(IoMdCheckmarkCircle)`
  font-size: 4rem;
  color: #00ff00;
  margin-bottom: 0.2em;
`;

const FormMessage = () => {
  return (
    <Container>
      <Link to="/">
        <Home />
      </Link>
      <Mark />
      <PayHeader>
        Home tutor request was sent successfully! We will get back to you
        shortly with details of your tutor and bill
      </PayHeader>
      <PaymentWrapper>
        <ExternalLink
          // href="https://api.whatsapp.com/send?phone=2348021468329"
          href="https://wa.me/message/VBBK4QNTB6W2P1"
          target="_blank"
        >
          <Image src={whatsapp} alt="WhatsApp" />
          <p>Chat us to pay</p>
        </ExternalLink>

        <ExternalLink href="tel:+2348021468329" target="_blank">
          <Image src={call} alt="CALL-IMAGE" />
          <p>Place a call to pay</p>
        </ExternalLink>
      </PaymentWrapper>
    </Container>
  );
};

export default FormMessage;

import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import emailjs from "@emailjs/browser";
import {
  ContactSection,
  ContactContainer,
  FormContainer,
  FormHeader,
  Form,
  Input,
  TextArea,
  Button,
  Select,
  Option,
  Div,
  InputLabel,
  CheckBox,
  ContactInfoContainer,
  ScheduleContainer,
  H2,
  H22,
  SelectSubjects,
  ImageLink,
  Linked,
  LinkToPrice,
  InputGroup,
  Fieldset,
  RadioLabel,
  RadioGroup,
  RadioInput,
} from "./FormInfoElements";
import Logo from "../../images/logo-academy.png";

const FormInfo = () => {
  const [values, setValues] = useState({
    fullName: "",
    email: "",
    phone: "",
    address: "",
    state: "",
    goal: "",
    subjects: "",
    inlesson: false,
    online: false,
    curriculum: "",
    sex: "",
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
    class: "",
    duration: "",
    startTime: "",
    basicMaths: "",
    verbal: "",
    english: "",
    basicScience: "",
    businessStudies: "",
    chemistry: "",
    generalMaths: "",
    physics: "",
    quantitative: "",
    computerScience: "",
    geography: "",
    js: "",
    gd: "",
    py: "",
    wd: "",
    uiux: "",
  });
  const [status, setStatus] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .send("service_jgk3ywq", "template_40pcph9", values, "bMl8NQX0gluC_eQzD")
      .then(
        (res) => {
          console.log("SUCCESS", res);
          setValues({
            fullName: "",
            email: "",
            phone: "",
            // address: "",
            state: "",
            goal: "",
            subjects: "",
            inlesson: false,
            online: false,
            curriculum: "",
            sex: "",
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
            class: "",
            duration: "",
            startTime: "",
            basicMaths: "",
            verbal: "",
            english: "",
            basicScience: "",
            businessStudies: "",
            chemistry: "",
            generalMaths: "",
            physics: "",
            quantitative: "",
            computerScience: "",
            geography: "",
            js: "",
            gd: "",
            py: "",
            wd: "",
            uiux: "",
          });
          setStatus(true);
        },
        (error) => {
          console.log("FAILED", error);
        }
      );
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setValues((values) => ({
      ...values,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  useEffect(() => {
    if (status) {
      const timer = setTimeout(() => {
        setStatus(false);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [status]);

  return (
    <ContactSection>
      <ContactContainer id="contact">
        <Linked to="/">
          <ImageLink>
            <img src={Logo} alt="LOGO" />
          </ImageLink>
        </Linked>
        
        <LinkToPrice to="/pricing">Check Pricing</LinkToPrice>

        <FormContainer>
          <Form onSubmit={handleSubmit}>
            <FormHeader>
              Book a tutor for your child or chat us 
            </FormHeader>

            <Div>
              <H22>Mode of home lesson?</H22>
              <InputGroup>
              <InputLabel>
                  <CheckBox
                    type="checkbox"
                    name="online"
                    id="online"
                    checked={values.online}
                    onChange={handleChange}
                  />
                  <label htmlFor="online">Online</label>
                </InputLabel>

                <InputLabel>
                  <CheckBox
                    type="checkbox"
                    name="inlesson"
                    id="inlesson"
                    checked={values.inlesson}
                    onChange={handleChange}
                  />
                  <label htmlFor="inlesson">In-Person</label>
                </InputLabel>
              </InputGroup>
            </Div>

            <Fieldset>
              <H22>What curriculum is used at your child's school?</H22>
              <RadioGroup>
                <RadioLabel>
                  <RadioInput
                    type="radio"
                    id="nigeria"
                    name="curriculum"
                    value="Nigeria"
                    checked={values.curriculum === "Nigeria"}
                    onChange={handleChange}
                  />
                  <label htmlFor="nigeria">Nigerian</label>
                </RadioLabel>

                <RadioLabel>
                  <RadioInput
                    type="radio"
                    id="british"
                    name="curriculum"
                    value="British"
                    checked={values.curriculum === "British"}
                    onChange={handleChange}
                  />
                  <label htmlFor="british">British</label>
                </RadioLabel>

                <RadioLabel>
                  <RadioInput
                    type="radio"
                    id="american"
                    name="curriculum"
                    value="American"
                    checked={values.curriculum === "American"}
                    onChange={handleChange}
                  />
                  <label htmlFor="american">American</label>
                </RadioLabel>

                <RadioLabel>
                  <RadioInput
                    type="radio"
                    id="notsure"
                    name="curriculum"
                    value="Not sure"
                    checked={values.curriculum === "Not sure"}
                    onChange={handleChange}
                  />
                  <label htmlFor="notsure">Not sure</label>
                </RadioLabel>
              </RadioGroup>
            </Fieldset>

            <RadioGroup>
              <H2>Do you prefer male or female tutor?</H2>
              <RadioLabel>
                <RadioInput
                  type="radio"
                  id="male"
                  name="sex"
                  value="Male"
                  checked={values.sex === "Male"}
                  onChange={handleChange}
                />
                <label htmlFor="male">Male</label>
              </RadioLabel>

              <RadioLabel>
                <RadioInput
                  type="radio"
                  id="female"
                  name="sex"
                  value="Female"
                  checked={values.sex === "Female"}
                  onChange={handleChange}
                />
                <label htmlFor="female">Female</label>
              </RadioLabel>

              <RadioLabel>
                <RadioInput
                  type="radio"
                  id="anygender"
                  name="sex"
                  value="Any gender"
                  checked={values.sex === "Any gender"}
                  onChange={handleChange}
                />
                <label htmlFor="anygender">Any gender</label>
              </RadioLabel>
            </RadioGroup>

            <ContactInfoContainer>
              <H2>Contact information</H2>
              <Input
                type="text"
                name="fullName"
                value={values.fullName}
                onChange={handleChange}
                placeholder="Name"
                required
              />

              <Input
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                placeholder="Active e-mail"
                required
              />

              <Input
                type="tel"
                name="phone"
                value={values.phone}
                onChange={handleChange}
                placeholder="Phone"
                required
              />

              {/* <Input
                type="text"
                name="address"
                value={values.address}
                onChange={handleChange}
                placeholder="Address"
                required
              /> */}

              <Input
                type="text"
                name="state"
                value={values.state}
                onChange={handleChange}
                placeholder="State"
                required
              />
            </ContactInfoContainer>

            <div>
              <H22>Lesson Schedule</H22>
              <ScheduleContainer>
                <InputLabel>
                  <CheckBox
                    type="checkbox"
                    name="monday"
                    id="monday"
                    checked={values.monday}
                    onChange={handleChange}
                  />
                  <label htmlFor="monday">Monday</label>
                </InputLabel>

                <InputLabel>
                  <CheckBox
                    type="checkbox"
                    name="tuesday"
                    id="tuesday"
                    checked={values.tuesday}
                    onChange={handleChange}
                  />
                  <label htmlFor="tuesday">Tuesday</label>
                </InputLabel>

                <InputLabel>
                  <CheckBox
                    type="checkbox"
                    name="wednesday"
                    id="wednesday"
                    checked={values.wednesday}
                    onChange={handleChange}
                  />
                  <label htmlFor="wednesday">Wednesday</label>
                </InputLabel>

                <InputLabel>
                  <CheckBox
                    type="checkbox"
                    name="thursday"
                    id="thursday"
                    checked={values.thursday}
                    onChange={handleChange}
                  />
                  <label htmlFor="thursday">Thursday</label>
                </InputLabel>

                <InputLabel>
                  <CheckBox
                    type="checkbox"
                    name="friday"
                    id="friday"
                    checked={values.friday}
                    onChange={handleChange}
                  />
                  <label htmlFor="friday">Friday</label>
                </InputLabel>

                <InputLabel>
                  <CheckBox
                    type="checkbox"
                    name="saturday"
                    id="saturday"
                    checked={values.saturday}
                    onChange={handleChange}
                  />
                  <label htmlFor="saturday">Saturday</label>
                </InputLabel>

                <InputLabel>
                  <CheckBox
                    type="checkbox"
                    name="sunday"
                    id="sunday"
                    checked={values.sunday}
                    onChange={handleChange}
                  />
                  <label htmlFor="sunday">Sunday</label>
                </InputLabel>
              </ScheduleContainer>
            </div>

            <Input
              type="text"
              name="class"
              value={values.class}
              onChange={handleChange}
              placeholder="What class is your child in? e.g jss 1"
              required
            />

            <Select name="goal" onChange={handleChange} defaultValue="choose">
              <Option value="choose" disabled>
                What is your goal for this child?
              </Option>
              <Option value="Improve grade">Improved grade</Option>
              <Option value="Help with home-work">Help with home-work</Option>
              <Option value="Home schooling">Home schooling</Option>
              <Option value="Exam prep">Exam preparation</Option>
              <Option value="Read and write">Able to read and write</Option>
              <Option value="Tech skill">Tech skill acquisition</Option>
              <Option value="Tech and academics">Tech and academics</Option>
            </Select>

            <div>
              <H22>Select subjects</H22>
              <SelectSubjects>
                <InputLabel>
                  <CheckBox
                    type="checkbox"
                    name="basicMaths"
                    id="basic"
                    checked={values.basicMaths}
                    onChange={handleChange}
                  />
                  <label htmlFor="basic">Basic mathematics</label>
                </InputLabel>

                <InputLabel>
                  <CheckBox
                    type="checkbox"
                    name="verbal"
                    id="verbal"
                    checked={values.verbal}
                    onChange={handleChange}
                  />
                  <label htmlFor="verbal">Verbal Reasoning</label>
                </InputLabel>

                <InputLabel>
                  <CheckBox
                    type="checkbox"
                    name="english"
                    id="english"
                    checked={values.english}
                    onChange={handleChange}
                  />
                  <label htmlFor="english">English</label>
                </InputLabel>

                <InputLabel>
                  <CheckBox
                    type="checkbox"
                    name="basicScience"
                    id="basicScience"
                    checked={values.basicScience}
                    onChange={handleChange}
                  />
                  <label htmlFor="basicScience">Basic Science</label>
                </InputLabel>

                <InputLabel>
                  <CheckBox
                    type="checkbox"
                    name="businessStudies"
                    id="businessStudies"
                    checked={values.businessStudies}
                    onChange={handleChange}
                  />
                  <label htmlFor="businessStudies">Business Studies</label>
                </InputLabel>

                <InputLabel>
                  <CheckBox
                    type="checkbox"
                    name="chemistry"
                    id="chemistry"
                    checked={values.chemistry}
                    onChange={handleChange}
                  />
                  <label htmlFor="chemistry">Chemistry</label>
                </InputLabel>

                <InputLabel>
                  <CheckBox
                    type="checkbox"
                    name="generalMaths"
                    id="general"
                    checked={values.generalMaths}
                    onChange={handleChange}
                  />
                  <label htmlFor="general">General Mathematics</label>
                </InputLabel>

                <InputLabel>
                  <CheckBox
                    type="checkbox"
                    name="physics"
                    id="physics"
                    checked={values.physics}
                    onChange={handleChange}
                  />
                  <label htmlFor="physics">Physics</label>
                </InputLabel>

                <InputLabel>
                  <CheckBox
                    type="checkbox"
                    name="quantitative"
                    id="quantitative"
                    checked={values.quantitative}
                    onChange={handleChange}
                  />
                  <label htmlFor="quantitative">Quantitative Reasoning</label>
                </InputLabel>

                <InputLabel>
                  <CheckBox
                    type="checkbox"
                    name="computerScience"
                    id="computerScience"
                    checked={values.computerScience}
                    onChange={handleChange}
                  />
                  <label htmlFor="computerScience">Computer science</label>
                </InputLabel>

                <InputLabel>
                  <CheckBox
                    type="checkbox"
                    name="geography"
                    id="geography"
                    checked={values.geography}
                    onChange={handleChange}
                  />
                  <label htmlFor="geography">Geography</label>
                </InputLabel>
              </SelectSubjects>
            </div>

            <TextArea
              value={values.message}
              name="subjects"
              onChange={handleChange}
              placeholder="Add other subjects here"
            />

            <hr />

            <div>
              <H22>Do you want a tech skill?</H22>
              <SelectSubjects>
                <InputLabel>
                  <CheckBox
                    type="checkbox"
                    name="js"
                    id="js"
                    checked={values.js}
                    onChange={handleChange}
                  />
                  <label htmlFor="js">JavaScript Programming</label>
                </InputLabel>

                <InputLabel>
                  <CheckBox
                    type="checkbox"
                    name="gd"
                    id="gd"
                    checked={values.gd}
                    onChange={handleChange}
                  />
                  <label htmlFor="gd">Graphics design</label>
                </InputLabel>

                <InputLabel>
                  <CheckBox
                    type="checkbox"
                    name="py"
                    id="py"
                    checked={values.py}
                    onChange={handleChange}
                  />
                  <label htmlFor="py">Python Programming</label>
                </InputLabel>

                <InputLabel>
                  <CheckBox
                    type="checkbox"
                    name="wd"
                    id="wd"
                    checked={values.wd}
                    onChange={handleChange}
                  />
                  <label htmlFor="wd">Web design & development</label>
                </InputLabel>

                <InputLabel>
                  <CheckBox
                    type="checkbox"
                    name="uiux"
                    id="uiux"
                    checked={values.uiux}
                    onChange={handleChange}
                  />
                  <label htmlFor="uiux">UI/UX design</label>
                </InputLabel>
              </SelectSubjects>
            </div>

            <Select
              name="duration"
              onChange={handleChange}
              defaultValue="choose"
              required
            >
              <Option value="choose" disabled>
                How many hours per day?
              </Option>
              <Option value="1 hour">1 hour</Option>
              <Option value="1.5 hours">1.5 hours</Option>
              <Option value="2 hours">2 hours</Option>
              <Option value="2.5 hours">2.5 hours</Option>
              <Option value="3 hours">3 hours</Option>
              <Option value="3.5 hours">3.5 hours</Option>
              <Option value="4 hours">4 hours</Option>
            </Select>

            <Input
              type="text"
              name="startTime"
              value={values.startTime}
              onChange={handleChange}
              placeholder="Enter start time e.g 4pm"
              required
            />

            {status &&  <Navigate to="/form-message" replace={true} />}
            <Button type="submit" value="Submit" />
          </Form>
        </FormContainer>
      </ContactContainer>
    </ContactSection>
  );
};

export default FormInfo;

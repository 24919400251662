import styled, { keyframes } from "styled-components";
import { MdArrowForward, MdKeyboardArrowRight } from "react-icons/md";
import bgImage from "../../images/tab-learning-2.jpg";

export const HeroContainer = styled.section`
  background-image: url(${bgImage});
  background-repeat: no-repeat;
  background-size: cover;

  position: relative;
  z-index: 1;
  height: 100vh;

  &:before {
    content: "";
    position: absolute;
    inset: 0;
    background: #12141d;
    /* background: linear-gradient(to top right, #040406, #12141d); */
    opacity: 0.8;
    z-index: -2;
  }
`;

export const HeroChild = styled.div`
  position: absolute;
  inset: 0;
  max-width: 1250px;
  margin: auto;
  padding-inline: 2em;

  display: flex;
  justify-content: space-around;
  gap: 0.4em;
  align-items: center;

  @media screen and (max-width: 48em) {
    flex-direction: column;
    align-items: center;
    padding: 2em;
  }
`;

export const HeroContent = styled.div`
  z-index: 3;
  padding-top: 2em;
  display: flex;
  flex-direction: column;
  margin-top: 6em;

  @media screen and (max-width: 48em) {
    align-items: center;
    justify-content: center;
    margin-left: 0;
    padding-top: 3em;
    /* padding-top: 8em; */
    padding-right: 0;
  }
`;

export const HeroImageContainer = styled.div`
  z-index: 1;
  margin-top: 6em;
  max-width: 16em;

  @media screen and (max-width: 71em) {
    padding-top: 3em;
  }
  @media screen and (max-width: 48em) {
    display: none;
  }
`;

const jumping = keyframes`
 0%{
  transform: rotate(0deg);
 }

 25%{
  transform: translateY(8px);
 }

 75%{
  transform: translateY(-8px);
 }

 100%{
  transform: rotate(0deg);
 }
`;

export const HeroImage = styled.img`
  background-color: #c7e6f8;
  border: 8px solid #c7e6f8;
  border-radius: 10px;
  max-width: 100%;
  height: auto;
  animation: ${jumping} linear 2s infinite;

  @media screen and (max-width: 30em) {
    border: 6px solid #c7e6f8;
  }
`;

export const HeroH1 = styled.h1`
  color: #c7e6f8;
  font-size: 2.2rem;
  font-family: "Poppins", sans-serif;
  line-height: 1.2;

  @media screen and (max-width: 48em) {
    font-size: 2rem;
    text-align: center;
  }
  @media screen and (max-width: 30em) {
    font-size: 1.6rem;
  }
`;

export const HeroP = styled.p`
  margin-top: 0.6em;
  color: #fff;
  opacity: 0.9;
  font-size: 1.6rem;
  font-weight: 400;
  max-width: 22em;
  letter-spacing: 0.3px;

  @media screen and (max-width: 48em) {
    text-align: center;
    font-size: 1.6rem;
    line-height: 1.5;
  }

  @media screen and (max-width: 30em) {
    font-size: 1.25rem;
    margin-top: 0.4em;
  }
`;

export const HeroBtnWrapper = styled.div`
  margin-top: 1.8em;
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
  max-width: 15em;

  @media screen and (max-width: 30em) {
    margin-bottom: 4.5em;
  }
`;

export const ArrowForword = styled(MdArrowForward)`
  margin-left: 0.5em;
  font-size: 1.5rem;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 0.5em;
  font-size: 1.5rem;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
`;

export const TopImage = styled.div`
  max-width: 4em;
  position: absolute;
  top: 6em;
  
  z-index: 33;
  display: none;
  
  @media screen and (max-width: 48em) {
    display: block;
  }
  @media screen and (max-width: 22em) {
    
    top: 6em;
  }
`;

export const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
  filter: brightness(35%);
`;

import React from 'react'
import Form  from '../components/Form'
import Footer from "../components/Footer";

const FormInfo = () => {
  return (
    <div>
      <Form />
      <Footer />
    </div>
  )
}

export default FormInfo

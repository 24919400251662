import styled from "styled-components";

export const BusinessContainer = styled.section`
  background: #fff;
  padding: 4em 1.6em 3em 1.6em;
`;

export const Ad = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3em;
  padding-top: 1.6em;

  @media screen and (max-width: 48em) {
    flex-direction: column;
    text-align: center;
  }
`;

export const H1 = styled.h1`
  font-family: "Poppins", sans-serif;
  font-size: 1.8rem;
  line-height: 1.1;

  @media screen and (max-width: 48em) {
    font-size: 1.6rem;
    padding-bottom: 0.4em;
  }
  @media screen and (max-width: 30em) {
    font-size: 1.4rem;
    line-height: 1.14;
  }
`;

export const Para = styled.p`
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  color: #fff;
  font-weight: 500;
  border-radius: 3px;
  background: linear-gradient(to bottom right, #053146, #00e3ae);
  margin-top: 0.5em;
  transform: skewX(10deg);
  padding: 0.8em 0.8em 0.8em 1.4em;

  @media screen and (max-width: 48em) {
    font-size: 1.15rem;
    padding: 0.8em;
  }
  @media screen and (max-width: 30em) {
    font-size: 1.1rem;
  }
`;

export const Div = styled.div``;

export const AdImgBox = styled.div`
  max-width: 25em;

  img {
    width: 100%;
  }
`;

export const ButtonBox = styled.div`
  margin-top: 1.7em;
`;

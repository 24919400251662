import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages";
import Pricing from './pages/Pricing'
import PayWindow from "./pages/PayWindow";
import Chat from "./components/Chat";
import Contact from "./pages/Contact";
import FormInfo from "./pages/FormInfo";
import PayInfoPage from "./pages/PayInfoPage";
import FormMessage from "./pages/FormMessage";
// import SkillLandingPage from "./pages/SkillLandingPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/pay" element={<PayWindow />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/form" element={<FormInfo />} />
        <Route path="/pay-for-tech-camp" element={<PayInfoPage />} />
        <Route path="/form-message" element={<FormMessage />} />
        {/* <Route path="/summer-coding-form" element={<SkillLandingPage />} /> */}
      </Routes>
      <Chat />
    </Router>
  );
}

export default App;

import {
  ContactSection,
  ContactInfoContainer,
  ContactContainer,
  FormContainer,
  FormHeader,
  Form,
  Button,
  Linked,
  ImageLink,
  H2,
  Span,
  PaystackImgDiv,
  PaystackSpan,
  Card,
  Bank,
  Arrow,
  PaymentWrapper,
  Image,
  ExternalLink,
} from "./PayInfoEl";
import Logo from "../../images/logo-lemans-dark.png";
import paystack from "../../images/Paystack.png";
import whatsapp from "../../images/whatsapp.png";
import call from "../../images/call.png";

const PayInfo = () => {
  return (
    <ContactSection>
      <ContactContainer id="contact">
        <Linked to="/">
          <ImageLink>
            <img src={Logo} alt="LOGO" />
          </ImageLink>
        </Linked>

        <Span>PRICE: 50K only</Span>

        <FormContainer>
          <Form>
            <FormHeader>Online Tech Skill Camp</FormHeader>

            <ContactInfoContainer>
              <H2>15th Dec - 14th April 2024</H2>
              <PaymentWrapper>
                <ExternalLink
                  // href="https://api.whatsapp.com/send?phone=2348021468329"
                  href="https://wa.me/message/VBBK4QNTB6W2P1"
                  target="_blank"
                >
                  <Image src={whatsapp} alt="WhatsApp" />
                  <p>Chat us to pay</p>
                </ExternalLink>

                <ExternalLink href="tel:+2348021468329" target="_blank">
                  <Image src={call} alt="CALL-IMAGE" />
                  <p>Call us to pay</p>
                </ExternalLink>
              </PaymentWrapper>
              <PaystackSpan>
                <span>Pay with CARD</span>
                <Card />
                <span style={{ marginLeft: "0.4em" }}>or BANK TRANSFER</span>
                <Bank />
              </PaystackSpan>
              <PaystackImgDiv>
                <Arrow />
                <img src={paystack} alt="LOGO" />
              </PaystackImgDiv>
            </ContactInfoContainer>

            <Button
              href="https://paystack.com/pay/tech-skill-camp"
              target="_blank"
            >
              Click to Pay Now!
            </Button>
          </Form>
        </FormContainer>
      </ContactContainer>
    </ContactSection>
  );
};

export default PayInfo;
